<template>
  <div>
    <h3 class="mb-5 text-secondary">{{ $t("page.adminUser.title") }}</h3>
    <admin-filters class="mb-5" />

    <b-row v-if="$is1Board" class="no-gutters justify-content-end mb-3">
      <b-col class="col-auto">
        <b-button size="md" variant="secondary" @click="openRegistrationModal">
          {{ $t("button.newAdmin") }}
        </b-button>
      </b-col>
    </b-row>

    <user-table
      :users="formatedUsers"
      :fields="fields"
      :actions="actions"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    />

    <admin-details :state.sync="userDetailsModalState" />
    <registration-modal :state.sync="registrationModalState" />
  </div>
</template>

<script>
import { appMixin, userMixin } from "./../mixins";

import UserTable from "./../components/User/UserTable";
import AdminDetails from "./../components/Administrator/AdminDetails";
import AdminFilters from "./../components/Administrator/AdminFilters";
import RegistrationModal from "../components/Administrator/RegistrationModal";
export default {
  components: {
    UserTable,
    AdminDetails,
    AdminFilters,
    RegistrationModal
  },
  mixins: [appMixin, userMixin],
  data() {
    return {
      userDetailsModalState: false,
      registrationModalState: false,
      fields: [
        { key: "fullName", label: "nameAndSurname", sort: true },
        { key: "email", label: "email", sort: true },
        { key: "createdAt", label: "createdat", sort: true },
        { key: "status", label: "status", sort: false },
        { key: "actions", label: "" }
      ],
      actions: [
        {
          label: "button.activate",
          variant: "secondary",
          callbackFunc: this.sendEmailConfirmation,
          hidden: this.isUserInactive
        },
        {
          label: "button.manage",
          variant: "secondary",
          callbackFunc: this.openUserDetailsModal
        },
        {
          label: "condominiums",
          variant: "secondary",
          callbackFunc: this.goToCondominiumPage,
          disabled: this.isUserInactive
        }
      ]
    };
  },
  computed: {
    adminUsers() {
      return this.$store.getters["admin/getAdminUsers"];
    },
    formatedUsers() {
      return this.adminUsers.map(item => {
        return {
          id: item._id,
          photo: this.$_getUserProfilePhoto(item.profile),
          fullName: this.$_getUserFullName(item.profile),
          email: item.email,
          status: this.mapStatus(item.status),
          createdAt: item.createdAt
        };
      });
    },
    sortBy: {
      get() {
        return this.$store.getters["admin/getSortBy"];
      },
      set(value) {
        this.$store.commit("admin/SET_SORT_BY", value);
      }
    },
    sortDesc: {
      get() {
        return this.$store.getters["admin/getSortDesc"];
      },
      set(value) {
        this.$store.commit("admin/SET_SORT_DESC", value);
      }
    }
  },
  methods: {
    openRegistrationModal() {
      this.registrationModalState = true;
    },
    mapStatus(status) {
      if (status === "notactivated") return "notverified";
      else return status;
    },
    isUserInactive(user) {
      return user.status === "notverified";
    },
    async sendEmailConfirmation(administratorId) {
      try {
        const adminInfo = this.adminUsers.find(admin => {
          return admin._id === administratorId;
        });

        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("user/identityConfirm", {
          userId: adminInfo._id,
          tool: "email",
          identityId: adminInfo.email
        });

        this.$store.commit("alert/SET_ALERT", {
          type: "success",
          messageText: "page.domiUser.registrationLinkMessage",
          messageParams: {
            mail: adminInfo.email
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async openUserDetailsModal(administratorId) {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("admin/retrieveAdminUser", administratorId);
        this.userDetailsModalState = true;
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    goToCondominiumPage(administratorId) {
      const adminInfo = this.adminUsers.find(admin => {
        return admin._id === administratorId;
      });
      this.$store.commit("filter/SET_ADMINISTRATOR_SELECTED", adminInfo);
      this.$router.push({ name: "Condominiums" });
    }
  }
};
</script>

<style lang="scss" scoped></style>
